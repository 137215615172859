<template>
    <div style="width: 100%;">
        <div
            style="display: flex;margin-top: 15px;align-items: center;width: 100%;padding: 0px 20px;box-sizing: border-box;">
            <div class="btn-week">
                <img src="../../../../assets/app/icon_arrow_black_left.png"
                    style="width: 18px;height: auto;object-fit: cover;">
                <p style="margin-left: 3px;" @click="requstLastWeek">上一周</p>
            </div>
            <p class="nav-week-info">{{ showWeekInfo }}</p>
            <div class="btn-week">
                <p class="btn-week" @click="requestNextWeek">下一周</p>
                <img src="../../../../assets/app/icon_arrow_black_right.png"
                    style="margin-left: 3px;width: 18px;height: auto;object-fit: cover;">
            </div>
        </div>
        <div v-if="thisWeekStartTime == currentWeekStartTime && morning_hint != ''" class="hint-container"
            style="margin-top: 15px;">
            提示：{{ morning_hint }}
        </div>
        <div style="margin-top: 15px;">
            <div v-for="(content, index) in dataList" :key="index" style="margin-top: 15px;">
                <div v-if="content.isInCurrentTime == true">

                </div>
                <p class="week-time">{{ formatItemWeekTime(content.time_stamp) }}</p>
                <div v-for="(childItem, childIndex) in Object.keys(content.week_datas)" :key="childIndex">
                    <p
                        style="color: #4E528D;font-size: 18px;margin-bottom: 5px;margin-top: 15px;width: 100%;padding-left: 20px;box-sizing: border-box;">
                        {{ getSubjectName(content.week_datas[childItem][0]) }}</p>
                    <div v-for="(song, songIndex) in content.week_datas[childItem]" :key="songIndex" class="song-item"
                        @click="clickItem(song)">
                        <div style="position: relative;width: 30px;">
                            <p
                                style="width: 13px;height: 13px;border-radius: 50%;border: 1px solid #686CC0;position: absolute;">
                            </p>
                            <img v-if="song.song_info.user_have_answer && song.song_info.user_have_answer != '' && song.song_info.user_have_answer != 'null'"
                                src="../../../../assets/app/answered.png"
                                style="position: absolute;;width: 20px;height: auto;object-fit: contain;">
                        </div>
                        <div class="song-name">
                            <p
                                :style="{ color: song.purchase_status == 'audition' && song.song_type != 'audition' ? 'gray' : '#333333' }">
                                {{ song.song_info.song_name }}</p>
                        </div>
                        <div v-if="song.purchase_status == 'audition'">
                            <p style="color: #686CC0;" v-if="song.song_type == 'audition'">{{ '试课' }}</p>
                            <p style="color:gray;" v-else>{{ '待解锁' }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="thisWeekStartTime == currentWeekStartTime"
                style="display: flex;align-items: center;width: 100%;padding: 20px;box-sizing: border-box;">
                <div style="width: 40px;background-color: #ff4444;height: 1px;"></div>
                <div
                    style="flex: 1;width: 100%;padding: 0px 10px;box-sizing: border-box;text-align: center;color:#ff4444;font-size: 16px;">
                    {{ formatItemWeekTimeForCurrent() }}
                </div>
                <div style="width: 40px;background-color: #ff4444;height: 1px;"></div>
            </div>
            <div v-if="thisWeekStartTime == currentWeekStartTime && night_hint != ''" class="hint-container">
                提示：{{ night_hint }}
            </div>
        </div>

        <el-dialog :visible.sync="showDialog" title="提示" width="95%">
            <p style="line-height: 22px;">{{ '当前课程包试课已结束，如需收听请解锁完整课程包。' }}</p>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showDialog = false">取消</el-button>
                <el-button type="primary" @click="gotoPackageDetail">解锁</el-button>
            </span>
        </el-dialog>


    </div>
</template>
<script>
import {
    getUserWeekSessions
} from '../../../../api/app'
import {
    formatDate
} from '../../../../utils/date'
import { Loading } from "element-ui";
export default ({
    name: 'index',
    data() {
        return {
            weekDays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            DAY_MILL_SECONDS: 1 * 24 * 60 * 60 * 1000,
            currentWeekStartTime: 0,
            thisWeekStartTime: 0,
            showWeekInfo: '',
            dataList: [],
            morning_hint: '',
            night_hint: '',
            showDialog: false,
            selectSongInfo: undefined
        }
    },
    mounted() {
        let date = new Date()
        let nowDayOfWeek = date.getDay()
        const firstDay = new Date(date.valueOf() - (nowDayOfWeek - 1) * this.DAY_MILL_SECONDS)
        firstDay.setHours(0, 0, 0, 0)
        this.currentWeekStartTime = firstDay.valueOf()
        this.thisWeekStartTime = firstDay.valueOf()
        this.requestCurrentWeek()
    },
    methods: {

        gotoPackageDetail() {
            this.$router.push({
                path: '/app-package',
                query: {
                    id: this.selectSongInfo.content_info._id.$id
                }
            })
        },

        clickItem(song) {
            if (song.purchase_status == 'audition') {
                if (song.song_type == 'audition') {
                    this.$message.success('请小朋友前往儿童版收听和答题')
                } else {
                    this.selectSongInfo = song
                    this.showDialog = true
                }
            } else {
                this.$message.success('请小朋友前往儿童版收听和答题')
            }
        },


        getSubjectName(firstData) {
            let name = ''
            if (firstData.content_type == 'album') {
                if (firstData.content_info.album_channel) {
                    name = firstData.content_info.album_channel
                }
            } else {
                if (firstData.content_info.collection_labels && firstData.content_info.collection_labels.length > 0) {
                    name = firstData.content_info.collection_labels[0]
                }
            }
            return name + ' • ' + firstData.song_info.album_name
        },

        formatItemWeekTime(time_stamp) {
            let date = new Date(time_stamp * 1000)
            let info1 = formatDate(new Date(date), 'MM月dd')
            let info2 = this.weekDays[date.getDay()]
            let hours = date.getHours()
            let end_hour = hours
            let mins = date.getMinutes()
            let end_min = mins + 15
            if (end_min >= 60) {
                end_min = 0
                end_hour + 1
            }
            let content = info1 + ' ' + info2 + ' ' + (hours > 9 ? hours : '0' + hours) + ':' + (mins > 9 ? mins : '0' + mins) + '-' + (end_hour > 9 ? end_hour : '0' + end_hour) + ':' + (end_min > 9 ? end_min : '0' + end_min)
            return content
        },
        formatItemWeekTimeForCurrent() {
            let date = new Date()
            let mm_dd = formatDate(date, 'MM月dd')
            let hh_mm = formatDate(date, 'hh:mm')
            let week = this.weekDays[date.getDay()]
            return '现在时间：' + mm_dd + ' ' + week + ' ' + hh_mm
        },

        requestCurrentWeek() {
            let firstDay = this.currentWeekStartTime
            let endDay = firstDay + 7 * this.DAY_MILL_SECONDS - 1
            let format_start = formatDate(new Date(firstDay), 'MM月dd')
            let format_end = formatDate(new Date(endDay), 'MM月dd')
            this.showWeekInfo = format_start + '-' + format_end
            this.requestSessions(firstDay)
        },

        requstLastWeek() {
            let firstDay = this.currentWeekStartTime - 7 * this.DAY_MILL_SECONDS
            let endDay = firstDay + 7 * this.DAY_MILL_SECONDS - 1
            let format_start = formatDate(new Date(firstDay), 'MM月dd')
            let format_end = formatDate(new Date(endDay), 'MM月dd')
            this.showWeekInfo = format_start + '-' + format_end
            this.requestSessions(firstDay)
        },

        requestNextWeek() {
            let firstDay = this.currentWeekStartTime + 7 * this.DAY_MILL_SECONDS
            let endDay = firstDay + 7 * this.DAY_MILL_SECONDS - 1
            let format_start = formatDate(new Date(firstDay), 'MM月dd')
            let format_end = formatDate(new Date(endDay), 'MM月dd')
            this.showWeekInfo = format_start + '-' + format_end
            this.requestSessions(firstDay)
        },

        requestSessions(start_time) {
            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            let time = start_time / 1000
            this.dataList = []
            this.morning_hint = ''
            this.night_hint = ''
            getUserWeekSessions(time).then((res) => {
                this.currentWeekStartTime = start_time
                if (res.data.code == 0) {
                    this.handleResponse(res.data.data)
                }
                loadingInstance.close()
            })
        },
        handleResponse(json) {
            let keys = Object.keys(json)
            let array = []
            keys.forEach((key) => {
                let content = {}
                content['time_stamp'] = key
                content['isInCurrentTime'] = this.isInCurrentTime(key)
                let map = {}
                let value = json[key]
                for (let i = 0; i < value.length; i++) {
                    let info = value[i]
                    let content_id = info.content_id
                    if (map[content_id]) {
                        let weekDatas = map[content_id]
                        weekDatas.push({
                            time_stamp: key,
                            arrange_type: info.arrange_type,
                            content_type: info.content_type,
                            content_info: info.content_info,
                            song_info: info.song_info,
                            purchase_status: info.purchase_status,
                            song_type: info.song_type ? info.song_type : ''
                        })
                        map[content_id] = weekDatas
                    } else {
                        let weekDatas = []
                        weekDatas.push({
                            time_stamp: key,
                            arrange_type: info.arrange_type,
                            content_type: info.content_type,
                            content_info: info.content_info,
                            song_info: info.song_info,
                            purchase_status: info.purchase_status,
                            song_type: info.song_type ? info.song_type : ''
                        })
                        map[content_id] = weekDatas
                    }
                }
                content['week_datas'] = map
                array.push(content)
            })
            array.sort((a, b) => {
                return a.time_stamp > b.time_stamp
            })
            this.dataList = array


            if (this.currentWeekStartTime == this.thisWeekStartTime) {
                let haveMorningSchedule = false
                let haveNightSchedule = false

                for (let i = 0; i < array.length; i++) {
                    let content = array[i]
                    let date = new Date(content.time_stamp * 1000)
                    let hours = date.getHours()
                    if (hours >= 6 && hours <= 10) {
                        haveMorningSchedule = true
                        break
                    }
                }
                for (let i = 0; i < array.length; i++) {
                    let content = array[i]
                    let date = new Date(content.time_stamp * 1000)
                    let hours = date.getHours()
                    if (hours >= 18 && hours <= 22) {
                        haveNightSchedule = true
                        break
                    }
                }
                if (haveMorningSchedule) {
                    let percent = 45 + Math.ceil(Math.random() * 10) + 1
                    this.morning_hint = percent + '%的家长在早晨6-10点的时间段给孩子安排了课时'
                }
                if (haveNightSchedule) {
                    let percent = 65 + Math.ceil(Math.random() * 10) + 1
                    this.night_hint = percent + '%的家长在晚上18-22点的时间段给孩子安排了课时'
                }
            }
        },
        isInCurrentTime(time_stamp) {
            let currentTime = new Date().valueOf()
            let start_time = time_stamp * 1000
            let end_time = start_time + 15 * 60 * 1000
            if (currentTime >= start_time && currentTime <= end_time) {
                return true
            }
            return false
        }
    }
})
</script>
<style lang="less" scoped>
.song-name {
    flex: 1;
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
}

.text-audition {
    background-color: #5d5bb5;
    color: white;
    padding: 4px 12px;
    border-radius: 4px;
    box-sizing: border-box;
}

.hint-container {
    margin: 0px 20px;
    padding: 10px 15px;
    box-sizing: border-box;
    color: #A1A1A1;
    font-size: 14px;
    line-height: 22px;
    background-color: #EFF0FF;
    border-radius: 10px;

}

.song-item {
    cursor: pointer;
    display: flex;
    padding: 10px 20px;
    color: #4d4d4d;
    font-size: 15px;
    border-bottom: 1px solid #f5f5f5;
}

.week-time {
    display: inline-block;
    color: white;
    font-size: 14px;
    padding: 10px 21px 10px 17px;
    background-color: #FFAF49;
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
}

.btn-week {
    cursor: pointer;
    color: #4d4d4d;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-week-info {
    flex: 1;
    text-align: center;
    color: #5d5bb5;
    font-size: 20px;
}
</style>