<template>
  <div class="schedule-container">
    <div class="top">
      <div style="width: 64px;position: relative;text-align: center;">
        <img src="../../../assets/app/schedule_parent.png" style="width: 100%;height: auto;object-fit: cover;">
        <div class="text-parent">家长版</div>
      </div>
      <div class="surpass_percent" style="width: 100%;padding-left: 15px;box-sizing: border-box;">
        <div>
          <p v-if="scheduleCount > 0">{{ '本周您的孩子已安排' + scheduleCount + '课时的收听计划，' }}</p>
          <p v-else>{{ '您的孩子还未制定任何收听计划，' }}</p>
        </div>
        <p v-if="scheduleCount > 0" style="margin-top: 8px;">{{ '超过 ' + surpass_percent + '％ 的孩子！' }}</p>
      </div>
    </div>
    <div class="schedule-content">
      <div style="display: flex;width: 100%;padding: 0px 20px;box-sizing: border-box;">
        <p :class="currentTab == 0 ? 'tab-item-selected' : 'tab-item'" @click="currentTab = 0">每周课表</p>
        <p :class="currentTab == 1 ? 'tab-item-selected' : 'tab-item'" @click="currentTab = 1">时间安排</p>
        <p :class="currentTab == 2 ? 'tab-item-selected' : 'tab-item'" @click="currentTab = 2">内容安排</p>
      </div>
      <div style="width: 100%;height: 1px;background-color: #f5f5f5;"></div>
      <WeekSchedule v-if="currentTab == 0" style="overflow-y: auto;"></WeekSchedule>
      <TimeSchedule v-if="currentTab == 1" style="overflow-y: auto;"></TimeSchedule>
      <ContentSchedule v-if="currentTab == 2" style="overflow-y: auto;"></ContentSchedule>
    </div>
  </div>
</template>
<script>
import { getUserSessionSetting, getSessionsRank } from "../../../api/app";
import { getHomeFragmentScheduleTabIndex, setHomeFragmentScheduleTabIndex } from "../../../utils/store";
import ContentSchedule from "./child/ContentSchedule.vue";
import TimeSchedule from "./child/TimeSchedule.vue";
import WeekSchedule from "./child/WeekSchedule.vue";

export default {
  name: "index",
  components: {
    ContentSchedule,
    TimeSchedule,
    WeekSchedule
  },
  data() {
    return {
      surpass_percent: 0,
      scheduleCount: 0,
      currentTab: 0
    };
  },
  beforeDestroy() {
    setHomeFragmentScheduleTabIndex(this.currentTab)
  },
  mounted() {
    let tab = getHomeFragmentScheduleTabIndex()
    if (tab) {
      this.currentTab = tab
    }
    setHomeFragmentScheduleTabIndex(0)
    this.requestDatas()
  },
  methods: {
    requestDatas() {
      this.surpass_percent = 0
      this.scheduleCount = 0
      getUserSessionSetting().then((res) => {
        if (res.data.code == 0) {
          let result = res.data.data
          let count = 0
          if (result.time_slots && result.time_slots.length > 0) {
            count = result.time_slots.length
          }
          this.scheduleCount = count
          if (count > 0) {
            this.requestSessionsRank(count)
          }
        }
      })
    },
    requestSessionsRank(count) {
      getSessionsRank(count).then((res) => {
        if (res.data.code == 0) {
          let result = res.data.data
          if (result.surpass_percent && result.surpass_percent != '') {
            this.surpass_percent = result.surpass_percent
          }
          this.surpass_percent = parseInt(this.surpass_percent * 1000)
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.tab-item {
  cursor: pointer;
  padding: 10px 0px;
  color: #9698A2;
  font-size: 14px;
  margin-right: 20px
}

.tab-item-selected {
  .tab-item();
  color: #5D5BB5;
  font-size: 16px;
}

.schedule-container {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #727bc9 0%, #5753b0 100%);

  .top {
    padding: 20px;
    height: 60px;
    display: flex;
    align-items: center;
  }

  .text-parent {
    margin-top: -15px;
    position: absolute;
    box-sizing: border-box;
    background-color: #ff9b1c;
    border-radius: 5px;
    padding: 3px 8px;
    color: white;
    font-size: 12px
  }

  .surpass_percent {
    color: white;
    font-size: 13px;
  }

  .schedule-content {
    width: 100%;
    flex: 1;
    overflow-y: auto;
    background: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px 0px 10px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}
</style>