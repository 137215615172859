<template>
  <div
    style="width: 100vw; padding: 0px 15px;background-color: #f5f5f5; box-sizing: border-box;height: 100%;display: flex;flex-direction: column;">
    <el-tabs>
      <el-tab-pane v-for="(tabPane, index) in tabDatas" :label="tabPane.label" :key="index">
        <KidWantsAlbum v-if="tabPane.type == 'childWants'" :albums="tabPane.content"
          @confirmRefuseKidApply="confirmRefuseKidApply"></KidWantsAlbum>
        <FoundChildFragment v-else :id="tabPane.content"></FoundChildFragment>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  getKidWantAlbums,
  getHomepageTab,
  refuseKidWantAlbum,
} from "../../../api/app";
import { getAppUserId } from "../../../utils/store";
import KidWantsAlbum from "../../../components/app/KidWantsAlbum.vue";
import FoundChildFragment from "./child/FoundChildFragment.vue";
export default {
  name: "index",
  components: {
    KidWantsAlbum,
    FoundChildFragment
  },
  data() {
    return {
      user_id: getAppUserId(),

      tabDatas: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.tabDatas = [];
      let kidData = await getKidWantAlbums(this.user_id);
      let wantAlbums = kidData.data.code == 0 ? kidData.data.data : []
      let homepageTabData = await getHomepageTab("parent");
      let homeTabs = homepageTabData.data.code == 0 ? homepageTabData.data.data : []

      if (wantAlbums.length > 0) {
        this.tabDatas.push({
          label: "孩子心愿",
          content: wantAlbums,
          type: "childWants",
        });
      }
      if (homeTabs.homepages_array) {
        for (let i = 0; i < homeTabs.homepages_array.length; i++) {
          let item = homeTabs.homepages_array[i];
          this.tabDatas.push({
            label: item.name,
            content: item.homepage_id,
            type: "pageTab",
          });
        }
      }
    },
    confirmRefuseKidApply(want_id) {
      refuseKidWantAlbum(want_id).then((res) => {
        this.loadData();
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-icon-arrow-left:before {
  content: "";
}

/deep/ .el-icon-arrow-right:before {
  content: "";
}

/deep/ .el-tabs__nav-wrap.is-scrollable {
  padding: 0px;
}

/deep/ .el-tabs__nav-wrap::after {
  // background-color: unset;
}
</style>