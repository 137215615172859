<template>
    <div class="time-schedule-container">
        <div style="flex: 1;width: 100%;overflow-y: auto;">
            <div v-for="(schedule, index) in dataList" :key="index" class="schedule-item">
                <p class="schedule-week" v-if="schedule.type == 0">{{ schedule.dayName }}</p>
                <p class="schedule-time" v-if="schedule.type == 0"> {{ schedule.timeName }}</p>
                <img src="../../../../assets/app/icon_dec.png" v-if="isEditMode && schedule.type == 0"
                    @click="doRemoveTime(index)" class="btn-desc">
                <img src="../../../../assets/app/icon_add.png" v-if="isEditMode && schedule.type == 1"
                    @click="showAddTimeDialog = true" class="btn-add">
            </div>
        </div>
        <div style="width: 100%;padding: 0px 20px;box-sizing: border-box;">
            <p class="btn-cancel-exchange" v-if="isEditMode" @click="cancelEdit">取消更改</p>
            <p class="btn-exchange-setting" @click="doAction"> {{ isEditMode ? '确认更新课时' : '更改课时设置' }}</p>
        </div>

        <el-dialog :visible.sync="showAddTimeDialog" title="添加时间" width="95%">
            <div>
                <h4 style="margin-top: 0px;text-align: center;">请安排课程时间：</h4>
                <div style="display: flex;">
                    <el-select v-model="addTimeInfo.week" placeholder="请选择" style="flex: 1;">
                        <el-option v-for="(week, index) in WEEKS" :key="index" :label="week" :value="week"></el-option>
                    </el-select>
                    <el-select v-model="addTimeInfo.hours" placeholder="请选择" style="flex: 1;padding: 0px 10px;">
                        <el-option v-for="(hour, index) in HOURS" :key="index" :label="hour" :value="hour"></el-option>
                    </el-select>
                    <el-select v-model="addTimeInfo.mins" placeholder="请选择" style="flex: 1;">
                        <el-option v-for="(min, index) in MINS" :key="index" :label="min" :value="min"></el-option>
                    </el-select>
                </div>
                <div style="margin-top: 20px;text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showAddTimeDialog = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAddTime">确 定</el-button>
                    </span>
                </div>

            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    getUserSessionSetting,
    updateUserSessionSetting,
    refreshFutureSessionArrangement_NEW
} from '../../../../api/app'
import {
    getAppUserId
} from '../../../../utils/store'
import { Loading } from "element-ui";
export default ({
    name: 'index',
    data() {
        return {
            user_id: getAppUserId(),
            HOURS: ['00时', '01时', '02时', '03时', '04时', '05时', '06时', '07时', '08时', '09时', '10时', '11时',
                '12时', '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时'],
            MINS: ['00分', '05分', '10分', '15分', '20分', '25分', '30分', '35分', '40分', '45分', '50分', '55分'],
            WEEKS: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            setting_id: '',
            dataList: [],
            isEditMode: false,
            originalCount: 0,
            showAddTimeDialog: false,
            addTimeInfo: {
                week: '',
                hours: '',
                mins: ''
            }
        }
    },
    mounted() {
        this.requestSessionSettings()
    },
    methods: {

        doRemoveTime(index) {
            this.dataList.splice(index, 1)
        },

        confirmAddTime() {
            let week = this.addTimeInfo.week
            let temp_hour = this.addTimeInfo.hours
            let temp_min = this.addTimeInfo.mins
            if (week == '' || temp_hour == '' || temp_min == '') {
                this.$message.error('请检查参数!')
                return
            }
            let dayOfWeek = 0
            for (let i = 0; i < this.WEEKS.length; i++) {
                if (this.WEEKS[i] == week) {
                    dayOfWeek = (i + 1)
                }
            }

            let hour = temp_hour.replace('时', '')
            let min = temp_min.replace('分', '')
            let timeValue = (hour + min)

            let duplicate = false
            for (let i = 0; i < this.dataList.length; i++) {
                let content = this.dataList[i]
                let compareTime = content.timeValue == timeValue
                let compareWeek = content.dayValue == dayOfWeek
                if (compareTime && compareWeek) {
                    duplicate = true
                    break
                }
            }
            if (duplicate == true) {
                this.$message.error('设置有重复～')
                return
            }
            let item = {
                type: 0,
                dayValue: dayOfWeek,
                dayName: week,
                timeValue: timeValue,
                timeName: timeValue.substring(0, 2) + ":" + timeValue.substring(2, 4),
                totalTime: this.getTotalTime(dayOfWeek, timeValue)
            }
            let length = this.dataList.length
            this.dataList.splice(length - 1, 0, item)
            this.showAddTimeDialog = false
        },

        async doAction() {
            if (this.isEditMode == false) {
                this.dataList.push({
                    type: 1,
                    dayValue: '',
                    dayName: '',
                    timeValue: '',
                    timeName: '',
                    totalTime: ''
                })
                this.isEditMode = true
                return
            }

            if (this.dataList.length <= 1) {
                this.$message.error('请添加时间安排')
                return
            }

            this.dataList.splice(this.dataList.length - 1, 1)

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "提交中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            this.isEditMode = false
            let diff = this.dataList.length - this.originalCount
            let time_slots = []
            this.dataList.forEach((element) => {
                if (element.type == 0) {
                    time_slots.push({
                        day: element.dayValue,
                        time: element.timeValue
                    })
                }
            })
            let params = {
                user_id: this.user_id,
                setting_id: this.setting_id,
                time_slots: time_slots
            }
            await updateUserSessionSetting(params)
            await refreshFutureSessionArrangement_NEW()

            if (diff == 0) {
                this.$message.success('更新成功')
            } else {
                if (diff > 0) {
                    this.$message.success('新增' + Math.abs(diff) + '个学习时间段')
                } else {
                    this.$message.success('减少' + Math.abs(diff) + '个学习时间段')
                }
            }
            this.requestSessionSettings()

            loadingInstance.close()

        },
        cancelEdit() {
            this.isEditMode = false
            this.requestSessionSettings()
        },

        requestSessionSettings() {

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "请求中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });
            this.originalCount = 0
            this.dataList = []
            getUserSessionSetting().then((res) => {
                let code = res.data.code
                if (code != 0) {
                    loadingInstance.close()
                    return
                }
                let result = res.data.data
                this.setting_id = result._id.$id
                let array = []
                if (result.time_slots) {
                    for (let i = 0; i < result.time_slots.length; i++) {
                        let content = result.time_slots[i]
                        if (!content.time) {
                            continue
                        }
                        let timeValue = content.time
                        let totalTime = this.getTotalTime(content.day, timeValue)
                        console.log('totalTime ->' + totalTime)
                        let obj = {
                            type: 0,
                            dayValue: content.day,
                            dayName: this.WEEKS[content.day - 1] ? this.WEEKS[content.day - 1] : '',
                            timeValue: timeValue,
                            timeName: timeValue.substring(0, 2) + ':' + timeValue.substring(2, 4),
                            totalTime: this.getTotalTime(content.day, timeValue)
                        }
                        array.push(obj)
                    }
                }
                array.sort((a, b) => {
                    return a.totalTime - b.totalTime
                })
                this.dataList = array
                this.originalCount = array.length
                loadingInstance.close()
            })
        },
        getTotalTime(day_time, time) {
            let startTime = new Date().valueOf() + day_time * 24 * 60 * 60 * 1000
            let hour = time.substring(0, 2)
            let minute = time.substring(2, 4)
            let totalTime = startTime + this.formatTime(hour) * 60 * 60 * 1000 + this.formatTime(minute) * 60 * 1000
            return totalTime
        },
        formatTime(str_hour) {
            let first = parseInt(str_hour.substring(0, 1))
            let second = parseInt(str_hour.substring(1, 2))
            return first * 10 + second
        }
    }
})
</script>
<style lang="less" scoped>
.btn-cancel-exchange {
    margin-bottom: 10px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-align: center;
    background-color: #aaaaaa;
    border-radius: 12px;
    padding: 13px 0px;
    box-sizing: border-box;
}


.btn-exchange-setting {
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-align: center;
    background-color: #FFAF53;
    border-radius: 12px;
    padding: 13px 0px;
    box-sizing: border-box;
}

.time-schedule-container {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.schedule-item {
    position: relative;
    width: 100%;
    height: 54px;
    line-height: 54px;
    display: flex;
    background-color: #EFF0FF;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.schedule-week {
    margin-left: 40px;
    color: #5d5bb5;
    font-size: 22px
}

.schedule-time {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    color: #5d5bb5;
    font-size: 22px;
    text-align: center;
}

.btn-add {
    cursor: pointer;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 32px;
    height: auto;
    object-fit: cover;
}

.btn-desc {
    cursor: pointer;
    right: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: auto;
    object-fit: cover;
}
</style>