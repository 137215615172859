<template>
  <div class="my-container">
    <div class="top"></div>
    <div class="content">
      <div style="padding: 20px 0px;box-sizing: border-box;color: white;font-size: 20px;">我的孩子</div>
      <div style="display: flex;width: 100%;padding: 10px 15px;box-sizing: border-box;position: relative;">
        <div style="flex: 1;width: 100%;padding-right: 15px;box-sizing: border-box;;">
          <div @click="goEditNickNameView"
            style="cursor: pointer;;display: inline-block;background-color: #433E84;border-radius: 22px;color: white;font-size: 16px;padding: 5px 12px;">
            {{
              userNickName != '' ? userNickName : '修改昵称' }}</div>
          <p style=";color: white;font-size: 14px;padding: 5px 12px;margin-top:5px">{{ userInfo.user_mobile }}</p>

          <div style="margin-top: 10px;display: flex;width: 100%;align-items: center;">
            <el-progress :percentage="exprogress" color="#FFCF98" style="flex: 1;padding-right: 5px;"
              :show-text="false"></el-progress>
            <p style="color: white;font-size: 8px;">经验值：{{ exp_points + '/' + nextLevel }}</p>
          </div>
        </div>
        <div style="position: relative;width: 66px;cursor: pointer;" @click="gotoExpTask">
          <img src="../../../assets/app/icon_default_avator.png" style="width: 66px;height: 66px;position: absolute;">
          <div
            style="position: absolute;bottom: 5px;left: 50%;transform: translateX(-50%);background-color: #FFAF53;color: white;font-size: 10px;border-radius: 7px;border: 2px solid white;padding: 2px 8px;">
            {{ userInfo.exp_level ? 'Lv.' + userInfo.exp_level : '--' }}
          </div>
        </div>
      </div>

      <div style="text-align: center;padding: 20px;box-sizing: border-box;width: 100%;">
        <div
          style="position: relative;;box-shadow: 0px 3px 15px 1px rgba(93,91,181,0.16);;display: flex;align-items: center;width:100%;background-color: white;height: 80px;border-radius: 12px;padding: 0px 15px;box-sizing: border-box;position: relative;">
          <div style="cursor: pointer;" @click="gotoMyWallet">
            <img src="../../../assets/app/icon_my_charge.png" style="width: 45px;height: auto;object-fit: contain;">
            <p style="color: #262c6b;font-size: 10px;">可能点充值</p>
          </div>
          <div style="flex: 1;padding-top: 20px;">
            <p style="color: #262c6b;font-size: 10px;"><span style="font-size: 32px;font-weight: bold;">{{
              userInfo.keneng_points ? userInfo.keneng_points : '0' }}</span>点</p>
          </div>
          <div style="cursor: pointer;">
            <img src="../../../assets/app/icon_my_reward.png" style="width: 45px;height: auto;object-fit: contain;">
            <p style="color: #262c6b;font-size: 10px;">可能点奖励</p>
          </div>
          <div
            style="color: white;font-size: 12px;position: absolute;top: 0px;left: 50%;transform: translateX(-50%);padding: 8px 40px;background-color: #262c6b;border-bottom-left-radius: 15px;border-bottom-right-radius: 15px;">
            {{ '可能点余额' }}
          </div>
        </div>
      </div>

      <div style="padding: 0px 15px;width: 100%;overflow-y: auto;box-sizing: border-box;">
        <div class="list-item" @click="gotoOrderList">
          <img src="../../../assets/app/icon_purchase_record.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">购买记录</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>

        <div class="empty-line"></div>
        <div class="list-item" @click="gotoQuizRecords">
          <img src="../../../assets/app/icon_trace.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">成长轨迹</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>

        <div class="empty-line"></div>
        <div class="list-item" @click="gotoWishList">
          <img src="../../../assets/app/icon_wish_list.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">心愿清单</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>

        <div class="empty-line"></div>
        <div class="list-item" @click="gotoFavSongs">
          <img src="../../../assets/app/icon_fav.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">我的收藏</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>

        <div class="empty-line"></div>
        <div class="list-item" @click="goRecentSongs">
          <img src="../../../assets/app/icon_recently_play.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">最近播放</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>

        <div class="empty-line"></div>
        <div class="list-item" @click="gotoReport">
          <img src="../../../assets/app/icon_report.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">举报</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>
        <div class="empty-line"></div>
        <div class="list-item" @click="gotoSettings">
          <img src="../../../assets/app/icon_settings.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">设置</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>
        <div class="empty-line"></div>
        <div class="list-item" @click="gotoFeedback">
          <img src="../../../assets/app/icon_feedback.png" style="width: 24px;height: auto;">
          <p style="flex: 1;color: #060606;font-size: 14px;padding-left: 10px;">意见反馈</p>
          <img src="../../../assets/app/icon_right_arrow.png" style="width: 12px;height: auto;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserByUserID, findKidsByUser, getNextLevelPoints } from "../../../api/app";
import { getAppUserId } from "../../../utils/store";
import { Loading } from "element-ui";
export default {
  name: "index",
  data() {
    return {
      userInfo: {},
      kid_id: '',
      userNickName: '',
      nextLevel: 0,
      exp_points: 0,
      exprogress: 0
    };
  },
  mounted() {
    this.requestUserInfo();
  },
  methods: {

    gotoQuizRecords(){
      this.$router.push({
        path: '/app-quizRecords'
      })
    },

    gotoFavSongs(){
      this.$router.push({
        path: '/app-favSongs'
      })
    },

    goRecentSongs() {
      this.$router.push({
        path: '/app-recentSongs'
      })
    },

    gotoReport() {
      this.$router.push({
        path: '/app-report'
      })
    },

    gotoFeedback() {
      this.$router.push({
        path: '/app-feedback'
      })
    },

    gotoWishList() {
      this.$router.push({
        path: '/app-wishList'
      })
    },

    gotoExpTask() {
      this.$router.push({
        path: '/app-expTask'
      })
    },

    goEditNickNameView() {
      this.$router.push({
        name: 'AppEditNickName',
        params: {
          kid_id: this.kid_id,
          kid_name: this.userNickName
        }
      })
    },
    gotoOrderList() {
      this.$router.push({
        path: '/app-orderList'
      })
    },

    gotoMyWallet() {
      this.$router.push({
        path: '/app-myWallet'
      })
    },
    gotoSettings() {
      this.$router.push({
        path: '/app-settings'
      })
    },

    requestUserInfo() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });

      getUserByUserID(getAppUserId()).then((res) => {
        if (res.data.code == 0) {
          this.userInfo = res.data.data
          this.exp_points = this.userInfo.exp_points
          this.getNextLevelPoints(this.userInfo.exp_level)
        }
        loadingInstance.close()
      });
      findKidsByUser(getAppUserId()).then((res) => {
        if (res.data.code == 0) {
          let result = res.data.data
          for (let i = 0; i < result.length; i++) {
            let content = result[i]
            if (content.kid_name && content.kid_name != '') {
              this.userNickName = content.kid_name
              this.kid_id = content._id.$id
              break
            }
          }
        }
      })
    },
    getNextLevelPoints(exp_level) {
      getNextLevelPoints(exp_level).then((res) => {
        let result = res.data
        this.nextLevel = result
        this.exprogress = parseInt((this.exp_points / this.nextLevel) * 100)
      })
    }
  },
};
</script>
<style lang="less" scoped>
.empty-line {
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
}

.list-item {
  cursor: pointer;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.my-container {
  position: relative;
  width: 100vw;
  height: 100vh;

  .top {
    position: absolute;
    width: 100%;
    height: 245px;
    background-image: url('../../../assets/app/bg_my_top.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
  }

  .content {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>