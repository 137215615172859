<template>
  <div style="width: 100%; height: 100%; background-color: #f5f5f5">
    <BannerView
      v-if="homePageData.banner_contents"
      :banners="homePageData.banner_contents"
    ></BannerView>
    <div style="margin-top: 15px">
      <div v-for="(block, index) in homePageData.block_contents" :key="index">
        <PackageView
          v-if="block.UI_layout == 'collection-package-view'"
          :package-data="block"
        ></PackageView>
      </div>
    </div>
  </div>
</template>
<script>
import { getHomepage } from "../../../../api/app";
import BannerView from "../../../../components/app/BannerView.vue";
import PackageView from "../../../../components/app/PackageView.vue";
export default {
  name: "index",
  components: {
    BannerView,
    PackageView,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  watch: {
    id: {
      handler(newVal, oldVal) {
        if (this.id == "") {
          return;
        }
        this.requestData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      homePageData: {},
    };
  },
  methods: {
    requestData() {
      getHomepage(this.id).then((res) => {
        let result = res.data.data;
        this.homePageData = result;
        // this.handlePageData(result)
      });
    },
    handlePageData(result) {
      if (result.banner_contents && result.banner_contents.length > 0) {
        //show banners
      }
      if (
        result.announcement_contents &&
        result.announcement_contents.length > 0
      ) {
        //show announcement
      }
      if (result.navigator_contents && result.navigator_contents.length > 0) {
        //show navigator
      }
      let array = result.block_contents;

      array.forEach((block) => {
        let layout = block.UI_layout;
        if (layout == "collection-page-view") {
          console.log("collection-page-view");
        } else if (layout == "collection-package-view") {
          //TODO:需要开发完成的
          console.log("collection-package-view");
        }
      });
    },
  },
};
</script>
