<template>
  <div style="width: 100vw; height: 100vh">
    <div class="home-content">
      <Schedule v-if="currentTab == 0"></Schedule>
      <Found v-if="currentTab == 1"></Found>
      <My v-if="currentTab == 2"></My>
    </div>
    <div class="tab-bottom">
      <div class="tab-item" @click="currentTab = 0">
        <img v-if="currentTab == 0" src="../../assets/app/tab_schedule_selected.png" style="width: 20px; height: 20px"
          class="icon" />
        <img v-else src="../../assets/app/tab_schedule_unselected.png" style="width: 20px; height: 20px" class="icon" />
        <p class="text">智能排课</p>
      </div>
      <div class="tab-item" @click="currentTab = 1">
        <img v-if="currentTab == 1" src="../../assets/app/icon_found_selected.png" style="width: 21px; height: 21px"
          class="icon" />
        <img v-else src="../../assets/app/icon_found_unselected.png" style="width: 21px; height: 21px" class="icon" />
        <p class="text">更多课程</p>
      </div>
      <div class="tab-item" @click="currentTab = 2">
        <img v-if="currentTab == 2" src="../../assets/app/icon_my_selected.png" style="width: 24px; height: 24px"
          class="icon" />
        <img v-else src="../../assets/app/icon_my_unselected.png" style="width: 24px; height: 24px" class="icon" />
        <p class="text">我的孩子</p>
      </div>
    </div>
  </div>
</template>
<script>
import { setHomeTabIndex, getHomeTabIndex } from "../../utils/store";
import Found from "./fragment/found.vue";
import My from "./fragment/my";
import Schedule from "./fragment/schedule.vue";
export default {
  name: "index",
  components: {
    Found,
    My,
    Schedule,
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  beforeDestroy() {
    setHomeTabIndex(this.currentTab)
  },
  mounted() {
    let home_tab = getHomeTabIndex()
    if (home_tab) {
      this.currentTab = home_tab
    }
    setHomeTabIndex(0)
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.home-content {
  width: 100vw;
  height: calc(100vh - 72px);
  overflow-y: auto;
}

.tab-bottom {
  width: 100vw;
  height: 72px;
  display: flex;
  border-top-color: #f5f5f5;
  border-top-width: 1px;
  border-top-style: solid;

  .tab-item {
    cursor: pointer;
    width: 100%;
    flex: 1;
    text-align: center;
    color: #333333;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      object-fit: cover;
    }

    .text {
      margin-top: 10px;
    }
  }
}
</style>
 
  