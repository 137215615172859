<template>
    <div class="content-container">
        <p style="color: #333333;font-size: 14px;line-height: 22px;">*每次课程的5个音频，将根据此处设置的数量，按比例自动安排。</p>
        <div v-if="dataList.length <= 0" style="color: #ff9b1c;font-size: 14px;line-height: 22px;margin-top: 5px;">
            您还未购买过任何课程，请先购买课程后再来设置课程权重。
        </div>
        <div style="display: flex;align-items: center;padding: 10px 0px;">
            <div class="table-item" style="flex: 4;">课程包</div>
            <div class="table-item" style="flex: 3;text-align: center;">进度</div>
            <div class="table-item" style="flex: 2;">每次听</div>
            <div class="table-item" style="flex: 2;text-align: center;">内容占比</div>
        </div>
        <div style="flex: 1;overflow-y: auto;padding-bottom: 15px;box-sizing: border-box;">
            <div v-for="(purchase, index) in dataList" :key="index" class="purchase-item">
                <div style="display: flex;align-items: center;flex: 4;">
                    <p style="color: #333333;font-size: 12px;font-weight: bold;">{{ purchase.this_purchased_type ==
                        'album' ? purchase.album_name : purchase.collection_name }}</p>
                    <p class="text-audition" v-if="checkIsAudition(purchase)">试</p>
                </div>

                <p style="color: #5D5BB5;font-size: 12px;font-weight: bold;flex: 3;text-align: center;">{{
                    calcProgress(purchase) }}</p>
                <div style="flex:2;width: 100%;cursor: pointer;" @click="editWeight(purchase, index)">
                    <p :class="isEditMode ? 'list-edit' : 'list-normal'">{{ calcListen(purchase) }}</p>
                </div>
                <p style="color: #5D5BB5;font-size: 12px;font-weight: bold;flex: 2;text-align: center;">{{
                    calcContentPercent(purchase) }}</p>
            </div>
        </div>
        <div style="width: 100%;">
            <p class="btn-cancel-weight" v-if="isEditMode" @click="cancelEdit">取消更改</p>
            <p class="btn-exchange-weight" @click="doAction"> {{ isEditMode ? '确认课程权重' : '更改课程权重' }}</p>
        </div>
    </div>
</template>
<script>
import {
    getUserAllPurchased,
    getContentWeightSetting,
    updateContentWeightSetting,
    refreshFutureSessionArrangement_NEW
} from '../../../../api/app'
import {
    getAppUserId
} from '../../../../utils/store'

import { Loading } from "element-ui";

export default ({
    name: 'index',
    data() {
        return {
            isEditMode: false,
            totalWeight: 0,
            contentWeightSetting: {},
            dataList: [],

            updateList: [],

            loadingInstance: undefined
        }
    },
    mounted() {
        this.requestData()
    },
    methods: {

        checkIsAudition(purchase) {

            if (purchase.purchase_info.purchase_type == 'collection') {
                if (!purchase.purchase_info.points_deducted || purchase.purchase_info.points_deducted == false) {
                    return true
                }
            }
            return false
        },

        editWeight(purchase, index) {
            if (!this.isEditMode) {
                return
            }
            //如果是已经完结的，不可以修改
            let total = 0
            let current = 0
            if (purchase.session_info) {
                let session_info = purchase.session_info
                if (session_info.code != 0) {
                    total = purchase.song_num
                } else {
                    total = session_info.data.total_count
                    current = session_info.data.next_song_count
                }
            }
            if (current > 0 && (current == total)) {
                this.$message.warning('已完结~')
                return
            }

            let currentContentWeight = purchase.content_weight
            let arrange_type = purchase.arrange_type
            let newContentWeight = 0
            if (arrange_type == 'alone') {
                if (currentContentWeight == 1) {
                    newContentWeight = 2
                }
                if (currentContentWeight == 2) {
                    newContentWeight = 3
                }
                if (currentContentWeight == 3) {
                    newContentWeight = 4
                }
                if (currentContentWeight == 4) {
                    newContentWeight = 0
                    arrange_type = 'mix'
                }
            } else {
                if (currentContentWeight == 0) {
                    newContentWeight = 1
                }
                if (currentContentWeight == 1) {
                    newContentWeight = 2
                }
                if (currentContentWeight == 2) {
                    newContentWeight = 3
                }
                if (currentContentWeight == 3) {
                    newContentWeight = 4
                }
                if (currentContentWeight == 4) {
                    newContentWeight = 1
                    arrange_type = 'alone'
                }
            }
            purchase.content_weight = newContentWeight
            purchase.arrange_type = arrange_type
            this.dataList[index] = purchase
            this.calcTotalWeight(this.dataList)
        },

        async requestData() {

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            let weightSettingResult = await getContentWeightSetting()
            let contentWeightSetting = null
            if (weightSettingResult.data.code == 0) {
                contentWeightSetting = weightSettingResult.data.data
            }
            let purchaseListResult = await getUserAllPurchased(-1)
            let purchaseList = purchaseListResult.data.code == 0 ? purchaseListResult.data.data : []

            let content_weights = null
            let hasContentWeights = false

            if (contentWeightSetting && contentWeightSetting.content_weights) {
                content_weights = contentWeightSetting.content_weights
                if (content_weights) {
                    hasContentWeights = true
                }
            }

            let array = []

            for (let i = 0; i < purchaseList.length; i++) {
                let purchase = purchaseList[i]
                let content_subject = ''
                let content_type = ''
                let content_weight = 0
                let arrange_type = 'mix'
                let song_num = 0
                if (purchase.song_num) {
                    song_num = purchase.song_num
                }
                let content_id = purchase._id.$id

                if (hasContentWeights) {
                    if (content_weights[content_id]) {
                        let content_weight_settings_info = content_weights[content_id]
                        content_weight = content_weight_settings_info['content_weight']
                        arrange_type = content_weight_settings_info['arrange_type']
                        if (content_weight_settings_info['content_type']) {
                            content_type = content_weight_settings_info['content_type']
                        }
                        if (content_weight_settings_info['content_subject']) {
                            content_subject = content_weight_settings_info['content_subject']
                        }
                    }
                }
                if (content_weight == 0) {
                    arrange_type = "mix"
                }
                purchase.song_num = song_num
                purchase.content_weight = content_weight
                purchase.arrange_type = arrange_type
                purchase.content_type = content_type
                purchase.content_subject = content_subject
                array.push(purchase)
            }
            this.dataList = array
            this.calcTotalWeight(array)
            loadingInstance.close()
        },

        calcContentPercent(purchase) {
            let arrange_type = purchase.arrange_type
            let content_weight = purchase.content_weight
            let info = ''
            if (arrange_type == 'mix') {
                let weight = 0
                if (this.totalWeight > 0) {
                    weight = new Number((content_weight / this.totalWeight) * 100).toFixed(2)
                }
                if (weight > 0) {
                    info = weight + '%'
                } else {
                    info = '已暂停'
                }
            } else {
                let weight = 0
                if (this.totalWeight > 0) {
                    weight = new Number(((content_weight * 5) / this.totalWeight) * 100).toFixed(2)
                }
                if (weight > 0) {
                    info = weight + '%'
                } else {
                    info = '已暂停'
                }
            }

            let total = 0
            let current = 0
            if (purchase.session_info) {
                let session_info = purchase.session_info
                if (session_info.code != 0) {
                    total = purchase.song_num
                } else {
                    total = session_info.data.total_count
                    current = session_info.data.next_song_count
                }
            }
            if (current > 0 && (current == total)) {
                info = '-'
            }
            return info
        },

        calcProgress(purchase) {
            let total = 0
            let current = 0
            if (purchase.session_info) {
                let session_info = purchase.session_info
                if (session_info.code != 0) {
                    total = purchase.song_num
                } else {
                    total = session_info.data.total_count
                    current = session_info.data.next_song_count
                }
            }
            return current + '/' + total
        },
        calcListen(purchase) {
            let arrange_type = purchase.arrange_type
            let content_weight = purchase.content_weight
            let info = ''
            if (arrange_type == 'mix') {
                info = content_weight + '个'
            } else {
                info = content_weight * 5 + '个'
            }
            let total = 0
            let current = 0
            if (purchase.session_info) {
                let session_info = purchase.session_info
                if (session_info.code != 0) {
                    total = purchase.song_num
                } else {
                    total = session_info.data.total_count
                    current = session_info.data.next_song_count
                }
            }
            if (current > 0 && (current == total)) {
                info = '已完结'
            }
            return info
        },

        cancelEdit() {
            this.isEditMode = false
            this.requestData()
        },
        doAction() {
            if (this.dataList.length <= 0) {
                this.$message.warning('你还没有购买任何课程')
                return
            }
            if (this.isEditMode == false) {
                this.isEditMode = true
                return
            }
            this.loadingInstance = Loading.service({
                fullscreen: true,
                text: "更新中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            this.updateList = []
            for (let i = 0; i < this.dataList.length; i++) {
                this.updateList.push(this.dataList[i])
            }
            this.updateContentWeightSetting()
        },
        updateContentWeightSetting() {
            if (this.updateList.length <= 0) {
                this.$message.success('已经更新完成了-')
                this.loadingInstance.close()
                this.refreshFutureSessionArrangement()
                this.requestData()
                this.isEditMode = false
                return
            }
            let purchase = this.updateList.shift()
            let content_weight = purchase.content_weight
            let arrange_type = purchase.arrange_type

            let content_type = purchase.content_type
            if (!content_type || content_type == '') {
                content_type = purchase.this_purchased_type
            }
            let content_subject = purchase.content_subject
            if (!content_subject || content_subject == '') {
                if (content_type != 'album') {
                    if (purchase.collection_subject) {
                        content_subject = purchase.collection_subject
                    }
                }
            }
            let params = {
                user_id: getAppUserId(),
                content_id: purchase._id.$id,
                content_type: content_type,
                content_weight: content_weight,
                content_subject: content_subject,
                arrange_type: arrange_type
            }
            updateContentWeightSetting(params).then((res) => {
                this.updateContentWeightSetting()
            })
        },

        calcTotalWeight(array) {
            this.totalWeight = 0
            for (let i = 0; i < array.length; i++) {
                let element = array[i]
                let arrange_type = element.arrange_type
                let content_weight = element.content_weight

                let finished = false
                let haveSession = false
                if (element.session_info && element.session_info.data && element.session_info.data != 'null') {
                    haveSession = true
                }
                if (haveSession) {
                    let total_albums = element.session_info.data.total_count
                    let next_album_count = element.session_info.data.next_song_count
                    if (total_albums == next_album_count) {
                        finished = true
                    }
                }
                if (!finished) {
                    if (arrange_type == "alone") {
                        this.totalWeight = content_weight * 5 + this.totalWeight
                    } else {
                        this.totalWeight = content_weight * 1 + this.totalWeight
                    }
                }
            }
        },
        refreshFutureSessionArrangement() {
            refreshFutureSessionArrangement_NEW().then((res) => {
            })
        }
    }
})
</script>
<style lang="less" scoped>
.text-audition {
    margin-left: 3px;
    color: #FFAF53;
    font-size: 12px;
}

.list-normal {
    display: inline-block;
    box-sizing: border-box;
    padding: 3px 6px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    background-color: #FFAF53;
    border-radius: 6px;
}

.list-edit {
    .list-normal();
    color: #FFAF53;
    background-color: #ffffff;
    border: 1px solid #FFAF53;
}

.purchase-item {
    width: 100%;
    padding: 15px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #666666;
}

.table-item {
    color: #5D5BB5;
    font-size: 14px;
    font-weight: bold;
}

.content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 0px 20px;
    box-sizing: border-box;
}

.btn-cancel-weight {
    margin-bottom: 10px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-align: center;
    background-color: #aaaaaa;
    border-radius: 12px;
    padding: 13px 0px;
    box-sizing: border-box;
}


.btn-exchange-weight {
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-align: center;
    background-color: #FFAF53;
    border-radius: 12px;
    padding: 13px 0px;
}
</style>