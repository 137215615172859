<template>
  <el-carousel height="143px">
    <el-carousel-item v-for="(item, index) in banners" :key="index">
      <img :src="item.banner_pic_address" style="width: 100%; height: 143px; cursor: pointer; object-fit: cover"
        @click="gotoTargetView(item)" />
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "index",
  props: {
    banners: {
      type: Array,
      default: [],
    },
  },
  watch: {
    banners: {
      handler(newVal, oldVal) {
        if (this.banners && this.banners.length > 0) {
          return;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    gotoTargetView(item) {
      console.log(JSON.stringify(item))
      let banner_jump_type = item.banner_jump_type;
      let params = item.banner_parameter;
      switch (banner_jump_type) {
        case "collection":
          break;
        case "collectionlist":
          break;
        case "channel":
          break;
        case "video":
          break;
        default:
          window.open(params)
          break;
      }
    },
  },
};
</script>
