<template>
  <div style="width: 100%;">
    <div v-for="(item, index) in albums" :key="index" class="wants-album-item" @click="gotoPackageDetail(item)">
      <div>
        <img v-if="item.cover_pic_url" :src="item.cover_pic_url" class="default-img" />
        <div v-else-if="item.album_info.pic_arr && item.album_info.pic_arr.length >= 4
          " class="collectionImg">
          <img v-for="i in 4" :key="i" :src="item.album_info.pic_arr[i - 1]" />
        </div>
        <img v-else-if="item.album_info.pic_arr && item.album_info.pic_arr.length > 0" :src="item.album_info.pic_arr[0]"
          class="default-img" />
        <img v-else src="../../assets/app/icon_default_album.png" class="default-img" />
      </div>
      <div style="flex: 1; width: 100%; padding: 0px 12px; box-sizing: border-box">
        <p style="color: #5d5bb5; font-size: 16px; font-weight: bold">
          {{ item.album_info.collection_name }}
        </p>
        <p style="
            color: #444444;
            font-size: 14px;
            margin-top: 5px;
            line-height: 18px;
          ">
          {{ item.album_info.collection_intro }}
        </p>
        <div style="text-align: right; margin-top: 5px">
          <p class="btn-refused" @click.stop="clickRefuseApply(item)">拒绝申请</p>
        </div>
      </div>
      <img src="../../assets/app/go.png" style="width: 28px; height: 28px; margin-top: 20px;cursor: pointer;" />
    </div>

    <el-dialog :visible.sync="showRefuseDialog" title="课程申请" width="85%">
      <span>您确定要拒绝孩子的课程申请吗?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = showRefuseDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmRefuseApply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "index",
  props: {
    albums: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      showRefuseDialog: false,
      selectItem: undefined,
    };
  },
  methods: {
    clickRefuseApply(item) {
      this.selectItem = item;
      this.showRefuseDialog = true;
    },
    confirmRefuseApply() {
      this.showRefuseDialog = false;
      this.$emit('confirmRefuseKidApply', this.selectItem._id.$id)
    },
    gotoPackageDetail(item) {
      this.$router.push({
        path: '/app-package',
        query: {
          id: item.album_id
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.btn-refused {
  cursor: pointer;
  color: white;
  font-size: 12px;
  display: inline-block;
  background-color: #ff5e45;
  border-radius: 5px;
  padding: 4px 12px;
  box-sizing: border-box;
}

.wants-album-item {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px 0px;
  justify-content: center;
  box-sizing: border-box;
  border-bottom-color: #eeeeee;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.default-img {
  width: 72px;
  height: 72px;
  border-radius: 12px;
  object-fit: cover;
}

.collectionImg {
  display: flex;
  flex-wrap: wrap;
  width: 72px;
  height: 72px;
  flex-shrink: 0;

  img {
    width: 50%;
    height: 50%;
  }

  img:nth-child(1) {
    border-radius: 12px 0 0 0;
  }

  img:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  img:nth-child(3) {
    border-radius: 0 0 0 12px;
  }

  img:nth-child(4) {
    border-radius: 0 0 12px 0;
  }
}
</style>
