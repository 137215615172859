<template>
  <div class="package-container">
    <div class="package-top">
      <img :src="head_pic_url" style="
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        " />
      <div style="
          display: flex;
          position: absolute;
          height: 100%;
          align-items: center;
        ">
        <img v-if="head_icon_url" :src="head_icon_url" style="width: 25px; height: auto; margin-left: 10px" />
        <img v-else src="../../assets/app/kewai_zazhi.png" style="width: 30px; height: auto; margin-left: 10px" />
        <p style="
            color: #ffffff;
            font-size: 16px;
            font-weight: bold;
            margin-left: 6px;
          ">
          {{ name }}
        </p>
      </div>
    </div>

    <div class="package-content">
      <div v-for="(content, index) in dataList" :key="index" class="package-content-item"
        @click="gotoPackageDetail(content)">
        <div style="width: 100%; flex: 1; padding: 20px; display: flex">
          <div v-if="checkCollectionCover(content) == 1">
            <img :src="content.album_contents[0].cover_pic_url" class="fistImg" />
          </div>
          <div v-else class="collectionImg">
            <img v-for="i in 4" :key="i" :src="content.album_contents[i - 1].cover_pic_url" />
          </div>
          <div style="
              width: 100%;
              flex: 1;
              padding-left: 12px;
              padding-right: 30px;
              box-sizing: border-box;
            ">
            <p class="collection-name">{{ content.collection_name }}</p>
            <p class="collection-intro">{{ content.collection_intro }}</p>
            <div style="display: flex; margin-top: 8px">
              <p class="album-count">
                共{{ content.album_contents.length }}个专辑
              </p>
              <p class="album-count" style="margin-left: 5px">
                上周{{ content.purchase_percent }}%家长订阅
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; flex: 1; padding: 0px 20px; position: relative;;">
          <div style="margin: -15px 35px 0px 0px;">
            <swiper :options="swiperOptions">
              <swiper-slide v-for="(album, index) in content.album_contents" :key="index">
                <div style="
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  width: 70px;
                  align-items: center;
                ">
                  <div style="
                    border: 1px solid #6f76c6;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-top: 20px;
                  ">
                    <img :src="album.cover_pic_url" style="
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      object-fit: cover;
                    " />
                  </div>
                  <div style="
                    margin-top: 12px;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    line-height: 16px;
                    background-color: #6f76c6;
                    border-radius: 50%;
                    font-size: 10px;
                    color: white;
                  ">
                    {{ index + 1 }}
                  </div>

                  <div v-if="album.purchase_status == 'no'" style="margin-top: -8px">
                    <img src="../../assets/app/ic_locker.png" style="width: 16px; height: 16px; object-fit: cover" />
                  </div>


                  <p style="color:#707070;font-size: 8px;margin-top: 5px;">{{ album.album_name }}</p>

                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAppKidId } from "../../utils/store";

import { fetchDataByGivenUrl } from "../../api/app";
export default {
  name: "index",
  props: {
    packageData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    packageData: {
      handler(newVal, oldVal) {
        if (this.packageData.data_request_function == "") {
          return;
        }
        this.requestData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        //设定初始化时slide的索引
        initialSlide: 0,
        //自动播放
        autoplay: false,
        direction: 'horizontal',
        width: 100
      },
      head_pic_url: "",
      head_icon_url: "",
      serverColor: "",
      name: "",
      dataList: [],
    };
  },
  methods: {

    gotoPackageDetail(content) {
      if (content.collection_type == '课程包') {
        this.$router.push({
          path: '/app-package',
          query: {
            id: content._id.$id
          }
        })
      } else {
        this.$router.push({
          path: '/app-collection',
          query: {
            id: content._id.$id
          }
        })
      }
    },

    checkCollectionCover(content) {
      let albumContents = content.album_contents;
      if (albumContents.length < 4) {
        return 1;
      }
      let enbale = 0;
      for (let i = 0; i < 4; i++) {
        let album = albumContents[i];
        if (!album.cover_pic_url) {
          enbale = 1;
          break;
        }
        return enbale;
      }
    },
    requestData() {
      this.head_pic_url = this.packageData.head_pic_url;
      this.head_icon_url = this.packageData.head_icon_url;
      this.serverColor = "6F76C6";
      if (this.packageData.serverColor && this.packageData.serverColor != "") {
        this.serverColor = this.packageData.serverColor;
      }
      if (this.serverColor.indexOf("#") >= 0) {
        this.serverColor = this.serverColor.replace("#", "");
      }
      this.name = this.packageData.name;

      let kid_id = getAppKidId();

      let requestParams = {
        function: this.packageData.data_request_function,
        kid_id: kid_id,
      };

      let params = this.packageData.data_request_parameters;
      let params_keys = Object.keys(params);
      params_keys.forEach((key) => {
        requestParams[key] = params[key];
      });

      fetchDataByGivenUrl(requestParams).then((res) => {
        let result = res.data.data;
        this.dataList = result.collection_contents;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.package-container {
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.package-top {
  position: relative;
  width: 100%;
  height: 50px;
}

.package-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  align-items: center;
  box-sizing: border-box;
}

.package-content-item {
  cursor: pointer;
  margin-bottom: 15px;
  width: 100%;
  background-image: url("../../assets/app/bg_collection_package.png");
  background-repeat: no-repeat;
  height: 68vw;
  background-size: contain;
  background-position: 100%;
  display: flex;
  flex-direction: column;
}

.fistImg {
  width: 84px;
  height: 84px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 12px;
}

.collectionImg {
  display: flex;
  flex-wrap: wrap;
  width: 84px;
  height: 84px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 12px;
  box-sizing: border-box;
  flex-shrink: 0;

  img {
    width: 50%;
    height: 50%;
  }

  img:nth-child(1) {
    border-radius: 12px 0 0 0;
  }

  img:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  img:nth-child(3) {
    border-radius: 0 0 0 12px;
  }

  img:nth-child(4) {
    border-radius: 0 0 12px 0;
  }
}

.collection-name {
  color: white;
  font-size: 18px;
}

.collection-intro {
  margin-top: 5px;
  font-size: 12px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 16px;
}

.album-count {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #ffffff;
  padding: 3px 6px;
  box-sizing: border-box;
  font-size: 10px;
  color: white;
}

.album-item {
  // display: inline-block;
  // position: relative;
  // width: 200px;
}
</style>
